input {
  &[type="search"] {
    padding: 10px 15px;
  }
}
input {
  &[type="email"],
  &[type="number"],
  &[type="search"],
  &[type="text"],
  &[type="tel"],
  &[type="url"],
  &[type="password"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: rem-calc(16px);
    border: 1px solid $input-border;
    background: #fbfbfb;
    border-radius: 4px;
    height: 40px;
    padding: 8px;
    width: 100%;
  }
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="radio"] {
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    font: inherit;
    color: #727272;
    width: 24px;
    height: 24px;
    border: 2px solid #727272;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    margin-top: 10px;
  }
  &[type="radio"]:hover {
    outline: max(2px, 0.2em) solid #faf9f8;
    outline-offset: max(-2px, 0em);
    outline-width: 6px;
    background-color: #faf9f8;
  }
  &[type="radio"]:checked {
    -webkit-appearance: none;
    appearance: none;
    font: inherit;
    color: $primary-color;
    width: 24px;
    height: 24px;
    border: 2px solid $primary-color;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }
  &[type="radio"]:checked:hover,
  &[type="radio"]:checked:focus {
    outline: max(2px, 0.2em) solid #f1f9fd;
    outline-offset: max(-2px, 0em);
    outline-width: 6px;
    background-color: #f1f9fd;
  }
  &[type="radio"]::before {
    content: "";
    width: 31px;
    height: 29px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }
  &[type="radio"]:checked::before {
    transform: scale(0.4);
    background-color: $primary-color;
  }
  &[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    font: inherit;
    color: #727272;
    border-radius: 2px;
    width: 24px;
    height: 24px;
    border: 2px solid #727272;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    margin-right: 5px;

  }
  &[type="checkbox"]:hover {
    outline: max(2px, 0.2em) solid #faf9f8;
    outline-offset: max(-2px, 0em);
    outline-width: 8px;
    background-color: #faf9f8;
  }
  &[type="checkbox"]:checked {
    -webkit-appearance: none;
    appearance: none;
    font: inherit;
    background: $primary-color;
    width: 24px;
    height: 24px;
    border: 2px solid $primary-color;
    border-radius: 2px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }
  &[type="checkbox"]:checked:hover,
  &[type="checkbox"]:checked:focus {
    outline: max(2px, 0.2em) solid #f1f9fd;
    outline-offset: max(-2px, 0em);
    outline-width: 8px;
  }
  &[type="checkbox"]::before {
    content: "";
    width: 31px;
    height: 29px;
    color: #ffffff;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }
  &[type="checkbox"]:checked:after {
    display: block;
    content: " ";
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    left: 7px;
    top: 3px;
  }
  &.alert {
    border: 1px solid #d10000;
  }
  &.success {
    border: 1px solid #33cc50;
  }
  &.disabled {
    background: #f3f3f3;
    border: 1px solid #c6c6c6;
    cursor: not-allowed;
    color: #b0b0b0;
    pointer-events: none;
    &[type="radio"] {
      color: #b0b0b0;
      border: 2px solid #b0b0b0;
      background: #ffffff;
    }
    &[type="radio"]:checked {
      color: #b0b0b0;
      border: 2px solid #b0b0b0;
    }
    &[type="radio"]:checked::before {
      background-color: #b0b0b0;
    }
    &[type="checkbox"] {
      background-color: #b0b0b0;
      border: 2px solid #b0b0b0;
      cursor: not-allowed;
    }
    &[type="checkbox"]:checked {
      border: 2px solid #b0b0b0;
      cursor: not-allowed;
    }
    &[type="checkbox"]:checked::before {
      background-color: #b0b0b0;
      cursor: not-allowed;
    }
  }
}
input {
  &[type="email"]:focus,
  &[type="number"]:focus,
  &[type="search"]:focus,
  &[type="text"]:focus,
  &[type="tel"]:focus,
  &[type="url"]:focus,
  &[type="password"]:focus {
    border: 1px solid $primary-color;
    outline: 0;
  }
}
input {
  &[type="checkbox"],
  &[type="radio"] {
    &.hidden {
      display: none;
    }
  }
}
input::placeholder {
  color: #727272;
}

*:focus {
  outline: none;
}
.form-actions {
  margin-top: 16px;
  &.secondary-actions,
  .secondary-actions2 {
    font-size: rem-calc(17px);
  }
}
.select {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}
select {
  background: #fbfbfb;
  border: 1px solid #727272;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  outline: 0;
  padding: 8px;
  width: 100%;
  color: #727272;

  &:hover,
  &:focus {
    color: $black;
    border: 1px solid #1a619f;
  }

  &:disabled {
    pointer-events: none;
    border: 1px solid #c6c6c6;
    background: #f3f3f3;
    color: #b0b0b0;
    cursor: not-allowed;
  }
}
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  min-height: 144px;
  padding: 8px 6px 8px 8px;
  width: 100%;
  border: 1.5px solid #1a619f;
  border-radius: 4px;
  font-size: 12px;
  overflow-y: scroll;
}
textarea::placeholder {
  color: $sg-steel;
}
textarea:focus,
select:focus {
  border: 1.5px solid #1a619f;
  outline: 0;
}
.select-arrow {
  border-color: #005895 transparent transparent transparent;
  border-style: solid;
  border-width: 8px 5px 0 5px;
  height: 0;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 22px;
  width: 0;
}
.validation-message {
  font-size: rem-calc(14px);
  &.success {
    color: $success;
  }
  &.warning {
    color: $warning;
  }
  &.alert {
    color: $alert;
  }
  &.secondary {
    color: $secondary-color;
  }

  &.font-smaller {
    font-size: rem-calc(13px);
  }
}
fieldset {
  padding: 0;
  border-width: 0;
  margin: 0;
  margin-bottom: 15px;
}
label,
legend {
  color: $black;
  display: block;
  font-family: $base-font-family;
  font-size: rem-calc(15px);
  font-weight: normal;
  letter-spacing: normal;
  margin-bottom: 13px;
  text-transform: none;
  width: 100%;
}
.radio-label {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: rem-calc(18px);
  font-family: $base-font-family-bold;
  margin-bottom: 20px;
}
.floating-label {
  position: relative;
  margin-bottom: 0px;
  margin-top: 7px;
  .floating-select {
    font-size: rem-calc(16px);
    padding: 4px 4px;
    display: block;
    width: 100%;
    background-color: transparent;
    border: none;
    color: #5e5e5e;
    border: solid 1px; /* Safari */
    border-color: #dadada;
    border-radius: 4px;
    caret-color: $primary-color;
    &:focus {
      outline: none;
      border: 2px solid $primary-color;
      label {
        width: 50%;
        top: -6px;
        font-size: 12px;
        color: $primary-color;
        width: 42px;
        background-color: #fff;
        &.long {
          width: 168px;
        }
      }
    }
    &.dirty {
      border-color: #868686;
      &:valid {
        ~ {
          label {
            top: -6px;
            font-size: rem-calc(12px);
            background-color: white;
            width: 42px;
            color: #5e5e5e;
            &.long {
              width: 168px;
            }
          }
        }
      }
    }
    &.ng-invalid {
      &.ng-touched {
        border-color: $alert !important;
        box-shadow: inset 1px 0 $alert, inset -1px 0 $alert, inset 0 -1px $alert;
        outline: none;
        + {
          span {
            color: $alert;
            &:before {
              border-top-color: $alert !important;
              box-shadow: inset 0 1px $alert;
            }
            &:after {
              border-top-color: $alert !important;
              box-shadow: inset 0 1px $alert;
            }
          }
        }
        &:not(:focus) {
          border-top-color: $alert !important;
          border-top-width: 3px;
        }
      }
      &.ng-dirty {
        border-color: $alert !important;
        box-shadow: inset 1px 0 $alert, inset -1px 0 $alert, inset 0 -1px $alert;
        outline: none;
        + {
          span {
            color: $alert;
            &:before {
              border-top-color: $alert !important;
              box-shadow: inset 0 1px $alert;
            }
            &:after {
              border-top-color: $alert !important;
              box-shadow: inset 0 1px $alert;
            }
          }
        }
        &:not(:focus) {
          border-top-color: $alert !important;
          border-top-width: 3px;
        }
      }
    }
  }
  label {
    color: #5e5e5e;
    font-size: rem-calc(16px);
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 14px;
    top: 22px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
}
.outlined-label-input {
  position: relative;
  display: inline-block;
  padding-top: 6px;
  font-family: $base-font-family;
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden;
  margin-bottom: 0;
  &:has(input.ng-touched.ng-invalid) {
    &:after {
      background-image: url('/assets/icon/alert-note.svg');
      background-size: 20px 20px;
      width: 19px; 
      position: absolute;
      top: 16px;
      right: 7px;
      height: 20px;
      content:"";
    }
  }
  &:has(input.ng-dirty.ng-valid) {
    &:after {
      background-image: url('/assets/icon/green-check.svg');
      background-size: 20px 20px;
      width: 19px; 
      position: absolute;
      top: 16px;
      right: 7px;
      height: 20px;
      content:"";
    }
  }
  img {
    position: absolute;
    right: 8px;
    width: 14px;
    height: 14px;
    top: 8px;
  }
  select {
    font-size: rem-calc(16px);
    padding: 4px 4px;
    display: block;
    width: 100%;
    background-color: transparent;
    border: none;
    color: #5e5e5e;
    border: solid 1px; /* Safari */
    border-color: #dadada;
    border-radius: 4px;
    caret-color: $primary-color;
    &:focus {
      outline: none;
      border: 2px solid $primary-color;
      span {
        width: 50%;
        top: -6px;
        font-size: 12px;
        color: $primary-color;
        width: 42px;
        background-color: #fff;
        &.long {
          width: 168px;
        }
      }
    }
    &.dirty {
      border-color: #868686;
      &:valid {
        ~ {
          span {
            top: -6px;
            font-size: rem-calc(12px);
            background-color: white;
            width: 42px;
            color: #5e5e5e;
            &.long {
              width: 168px;
            }
          }
        }
      }
    }
    &.ng-invalid {
      &.ng-touched {
        border-color: $alert !important;
        box-shadow: inset 1px 0 $alert, inset -1px 0 $alert, inset 0 -1px $alert;
        outline: none;
        + {
          span {
            color: $alert;
            &:before {
              border-top-color: $alert !important;
              box-shadow: inset 0 1px $alert;
            }
            &:after {
              border-top-color: $alert !important;
              box-shadow: inset 0 1px $alert;
            }
          }
        }
        &:not(:focus) {
          border-top-color: $alert !important;
          border-top-width: 3px;
        }
      }
      &.ng-dirty {
        border-color: $alert !important;
        box-shadow: inset 1px 0 $alert, inset -1px 0 $alert, inset 0 -1px $alert;
        outline: none;
        + {
          span {
            color: $alert;
            &:before {
              border-top-color: $alert !important;
              box-shadow: inset 0 1px $alert;
            }
            &:after {
              border-top-color: $alert !important;
              box-shadow: inset 0 1px $alert;
            }
          }
        }
        &:not(:focus) {
          border-top-color: $alert !important;
          border-top-width: 3px;
        }
      }
    }
      &:disabled {
        background-color: $disabled-grey;
        cursor: not-allowed;
      }
    //   &:not(:focus) {
    //       border-top-color: #dadada;
    //       + {
    //           span {
    //               font-size: inherit;
    //               line-height: 54px;
    //               &:before {
    //                   border-top-color: transparent;
    //               }
    //           }
    //       }
    //   }
    //   &.ng-invalid {
    //     &.ng-touched {
    //       border-color: $alert!important;
    //       border-top-color: transparent!important;
    //       box-shadow: inset 1px 0 $alert, inset -1px 0 $alert, inset 0 -1px $alert;
    //       outline: none;
    //       + {
    //           span {
    //               color: $alert;
    //               &:before {
    //                   border-top-color: $alert !important;
    //                   box-shadow: inset 0 1px $alert;
    //               }
    //               &:after {
    //                   border-top-color: $alert !important;
    //                   box-shadow: inset 0 1px $alert;
    //               }
    //           }
    //       }
    //       &:not(:focus) {
    //         border-top-color: $alert !important;
    //         border-top-width: 3px;
    //       }
    //       &:after {
    //         background-image: url('/assets/icon/alert-note.svg');
    //         background-size: 20px 20px;
    //         width: 19px; 
    //         position: absolute;
    //         top: 16px;
    //         right: 7px;
    //         height: 20px;
    //         content:"";
    //       }
    //     }
    //     &.ng-dirty {
    //       border-color: $alert!important;
    //       border-top-color: transparent!important;
    //       box-shadow: inset 1px 0 $alert, inset -1px 0 $alert, inset 0 -1px $alert;
    //       outline: none;
    //       + {
    //           span {
    //               color: $alert;
    //               &:before {
    //                   border-top-color: $alert !important;
    //                   box-shadow: inset 0 1px $alert;
    //               }
    //               &:after {
    //                   border-top-color: $alert !important;
    //                   box-shadow: inset 0 1px $alert;
    //               }
    //           }
    //       }
    //       &:not(:focus) {
    //         border-top-color: $alert !important;
    //         border-top-width: 3px;
    //       }
    //     }
    // }
  }
  input {
      box-sizing: border-box;
      margin: 0;
      border: solid 1px; /* Safari */
      border-color: #dadada;
      border-top-color: transparent;
      border-radius: 4px;
      padding: 8px;
      width: 100%;
      height: inherit;
      color: #000000DE;
      background-color: transparent;
      box-shadow: none; /* Firefox */
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      caret-color: $primary-color;
      transition: border 0.2s, box-shadow 0.2s;
      &:disabled {
        background-color: $disabled-grey;
        cursor: not-allowed;
      }
      &:not(:focus):placeholder-shown  {
          border-top-color: #dadada;
          + {
              span {
                  font-size: inherit;
                  line-height: 50px;
                  &:before {
                      border-top-color: transparent;
                  }
              }
          }
      }

      &:focus {
          border-color: $primary-color;
          border-top-color: transparent;
          box-shadow: inset 1px 0 $primary-color, inset -1px 0 $primary-color, inset 0 -1px $primary-color;
          outline: none;
          + {
              span {
                  color: $primary-color;
                  &:before {
                      border-top-color: $primary-color !important;
                      box-shadow: inset 0 1px $primary-color;
                  }
                  &:after {
                      border-top-color: $primary-color !important;
                      box-shadow: inset 0 1px $primary-color;
                  }
              }
          }
      }
      &.ng-invalid {
        &.ng-dirty{
          border-color: $alert;
          border-top-color: transparent;
          box-shadow: inset 1px 0 $alert, inset -1px 0 $alert, inset 0 -1px $alert;
          outline: none;
          + {
              span {
                  color: $alert!important;
                  &:before {
                      border-top-color: $alert !important;
                      box-shadow: inset 0 1px $alert;
                  }
                  &:after {
                      border-top-color: $alert !important;
                      box-shadow: inset 0 1px $alert;
                  }
              }
          }
          &:not(:focus):placeholder-shown {
            border-top-color: $alert!important;
            border-top-width: 3px;
          }
          &:focus {
            border-color: $alert!important;
            border-top-color: transparent!important;
            box-shadow: inset 1px 0 $alert, inset -1px 0 $alert, inset 0 -1px $alert;
            outline: none;
            + {
                span {
                    color: $alert!important;
                    &:before {
                        border-top-color: $alert!important;
                        box-shadow: inset 0 1px $alert;
                    }
                    &:after {
                        border-top-color: $alert !important;
                        box-shadow: inset 0 1px $alert;
                    }
                }
            }
          }
        }
        &.ng-touched {
          border-color: $alert;
          border-top-color: transparent;
          box-shadow: inset 1px 0 $alert, inset -1px 0 $alert, inset 0 -1px $alert;
          outline: none;
          + {
              span {
                  color: $alert!important;
                  &:before {
                      border-top-color: $alert !important;
                      box-shadow: inset 0 1px $alert;
                  }
                  &:after {
                      border-top-color: $alert !important;
                      box-shadow: inset 0 1px $alert;
                  }
              }
          }
          &:not(:focus):placeholder-shown {
            border-top-color: $alert!important;
            border-top-width: 3px;
          }
          &:focus {
            border-color: $alert!important;
            border-top-color: transparent!important;
            box-shadow: inset 1px 0 $alert, inset -1px 0 $alert, inset 0 -1px $alert;
            outline: none;
            + {
                span {
                    color: $alert!important;
                    &:before {
                        border-top-color: $alert!important;
                        box-shadow: inset 0 1px $alert;
                    }
                    &:after {
                        border-top-color: $alert !important;
                        box-shadow: inset 0 1px $alert;
                    }
                }
            }
          }
        }
      }
      
  }
  span {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      border-color: #dadada;
      width: 100%;
      max-height: 100%;
      color: #00000099;
      font-size: 75%;
      line-height: 15px;
      cursor: text;
      transition: color 0.2s, font-size 0.2s, line-height 0.2s;
      &:before, &:after {
          content: "";
          display: block;
          box-sizing: border-box;
          margin-top: 6px;
          border-top: solid 1px;
          border-top-color: #dadada;
          min-width: 10px;
          height: 8px;
          pointer-events: none;
          box-shadow: inset 0 1px transparent;
          transition: border-color 0.2s, box-shadow 0.2s;
      }
      &:before {
          margin-right: 4px;
          border-left: solid 1px transparent;
          border-radius: 4px 0;
      }
      &:after {
          flex-grow: 1;
          margin-left: 4px;
          border-right: solid 1px transparent;
          border-radius: 0 4px;
      }
  }
}