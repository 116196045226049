// Breakpoints
$larger-than-mobile: "min-width: 426px" !default;
$larger-than-phablet: "min-width: 640px" !default;
$larger-than-phablet-plus-one: "min-width: 641px" !default;
$larger-than-tablet: "min-width: 750px" !default;
$larger-than-desktop: "min-width: 1025px" !default;
$larger-than-desktophd: "min-width: 1200px" !default;
$desktop-1399-break-point: "1399.9px";
$old-mobile-min: "320px";
$mobile-min: "426px";
$phablet-min-width: "640px";
$phablet-min-width-plus-one: "641px";
$phablet-min-width-minus-one:"639px";
$phablet-min-width-minus: "639.9px";
$smaller-than-ipad:"max-width: 832.9px" !default;
$mobile-min-width-minus: "414px";
$desktop-min-width-minus: "1024.9px";
$tablet-min-width: "751px";
$tablet-min-width-minus: "749.9px";
$ipad-min-width: "835px";
$ipad-min-width-minus: "833.9px";
$desktop-min-width: "1025px";
$desktophd-min-width: "1200px";
$iphone-max-width: "475.9px";
// Responsive Grid System
$grid__bp-sm: 426;
$grid__bp-md: 640;
$grid__bp-lg: 750;
$grid__bp-xl: 1025;
$grid__bp-max: 1360;
$grid__cols: 12;

// Colors
$white-grey: #ebebeb !default;
$lightest-grey: #e2e2e2 !default;
$light-grey: #f2f2f2 !default;
$grey: #B1B1B1 !default;
$medium-grey: #868686 !default;
$dark-grey: #666666 !default;
$darkest-grey: #333333 !default;
$black: #090909 !default;
$black-700: #5E5E5E !default;
$blue: #74C0EB!default;
$disabled: #B0B0B0!default;
$white: #FBFBFB!default;
$primary-color: #005895 !default;
$primary-color-dark: #003C75 !default;
$primary-color-light: #f2e8f4 !default;
$secondary-color: #74C0EB!default;
$secondary-color-dark: #404C52 !default;
$third-color: #090909 !default;
$third-color-dark: #c16e3c !default; /* todo change*/
$hover-color: #FF972F !default;
$menu-bar-bg: #dbd5fa !default;
$menu-bar-font: #000000 !default;
$menu-bar-font-alt: #ffffff !default;
$link-color : #005895;
$header-color: #563e86;
$section-header-color: #563E86 !default;
$section-blue-background: #F1F9FD!default;
$purple: #765BA7!default;


$border-color: #979797 !default;
$link-color: $primary-color !default;
$text-color: $black !default;
$text-color-light: $medium-grey !default;
$input-border: #868686!default;

$footer-bg-light: #e3e1e3 !default;
$footer-bg-alt-light: #c3c1c3 !default;
$footer-bg: #042025 !default;
$footer-bg-alt: #446369 !default;
$header-bg: #7a888d !default;

$notification-bg: #fc9c46 !default;
$notification-bg-alt: #beb4f8 !default;
$notification-bg-critical: $primary-color !default;
$notification-bg-critical-alt: $menu-bar-bg !default;

$success: #008630 !default;
$warning: #f5a623 !default;
$alert: #D94A47 !default;

$red-700: #D10000 !default;
$red: #d0021b !default;
$dark-red: #CC3345 !default;
$yellow: #FFC25B !default;

// Google Fonts Import
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600);

// Font weights
$light: 300;
$regular: 400;
$bold: 600;

// Base Font
/*$base-font-family: "bryant", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !default;*/
$base-font-family: "Weissach", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$base-font-family-bold: "Weissach-bold", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$base-font-weight: $regular;
$base-font-size: 14px;
$base-line-height: 1.4;
$secondary-font-family: "calibri", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$secondary-font-family-bold: "calibri-bold", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$proxima-font-family: "proxima-nova", sans-serif !default;
$lato-font-family: "Lato", sans-serif !default;
// Fixed Font
$fixed-font-family: monospace;
$fixed-font-size: 85%;
$fixed-line-height: $base-line-height;

// Headings
$header-font-family: "Weissach-bold", "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$header-font-weight: $bold;

// Buttons
$button-font-family: "Weissach", "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$button-font-weight: $bold;

// Nav
$nav-font-family: "bryant", "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$nav-font-weight: $bold;

// Misc
$global-radius: 1px !default;
$assets-path: "/assets/img/" !default;

$sg-purple: #863399;
$sg-lavendar: #E2E2E2;
$sg-steel: #a5a3a3;
$sg-white: #FFFFFF;
$sg-orange: #F58520;
$sg-charcoal: #2a3638;
$sg-grey: #E2E2E2;
$sg-light-grey: #EAEAEA;

$grey: #dbd8d8;
$disabled-grey: #C6C6C6;
$light-charcoal: #848484;
$caption-grey: #9B9B9B;
//proxima-nova font Import from Adobe
@import url(https://use.typekit.net/fve0ycq.css);