// Typography
//––––––––––––––––––––––––––––––––––––––––––––––––––

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
  font-family: $header-font-family;
  font-weight: $regular;
  margin-top: 0;
  margin-bottom: 12px;
}

h1,
.h1 {
  font-size: rem-calc(36px);
  line-height: 44px;
  margin-bottom: 8px;
  $font-family: $header-font-family;
  font-weight: bold;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(26px);
    line-height: 38px;
  }
}

h2,
.h2 {
  font-size: rem-calc(32px);
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 12px;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(24px);
    line-height: 36px;
    margin-bottom: 8px;
  }
}

h3,
.h3 {
  font-size: rem-calc(28px);
  font-weight: 700;
  line-height: 36px;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(22px);
    line-height: 32px;
  }
}

h4,
.h4 {
  font-size: rem-calc(24px);
  font-weight: 700;
  line-height: 32px;
  font-family: $secondary-font-family-bold;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(20px);
    line-height: 28px;
  }
}

h5,
.h5 {
  font-size: rem-calc(20px);
  font-weight: 700;
  line-height: 28px;
  font-family: $secondary-font-family-bold;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(18px);
    line-height: 28px;
  }
}

h6,
.h6 {
  font-size: rem-calc(18px);
  font-weight: 700;
  line-height: 24px;
  font-family: $secondary-font-family;

  @media (max-width: $phablet-min-width-minus) {
    font-size: rem-calc(16px);
    line-height: 20px;
  }
}

p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: rem-calc(18px);
  font-weight: 400;
  line-height: 28px;
  font-family: $base-font-family;

  &.smaller {
    font-size: rem-calc(16px);
    line-height: 24px;
  }

  &.smallest {
    font-size: rem-calc(14px);
    line-height: 22px;
  }

  &.caption {
    font-size: rem-calc(12px);
    line-height: 20px;
    font-family: $secondary-font-family;
  }

  &.label {
    font-size: rem-calc(12px);
    line-height: 12px;
    font-family: $secondary-font-family;
  }

  &.footer {
    font-size: rem-calc(10px);
    line-height: 16px;
    font-family: $secondary-font-family;
  }

}

a {
  &.caption {
    font-size: rem-calc(12px);
    line-height: 20px;
    font-family: $secondary-font-family;
  }

  &.label {
    font-size: rem-calc(12px);
    line-height: 12px;
    font-family: $secondary-font-family;
  }
}

// Pre
pre {
  line-height: 1.6;
  margin: 8px 16px;
  white-space: pre-wrap;
}

// Text format helpers
.text-italic {
  font-style: italic;
}

.text-semibold {
  font-weight: 600;
}

.text-bold,
strong {
  font-weight: 700;
}

.text-strike {
  text-decoration: line-through;
}

.text-super {
  vertical-align: super;
}

.text-sub {
  vertical-align: sub;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

// Text align helpers
.text-left {
  text-align: left;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right;
}

.primary-font {
  font-family: $base-font-family !important;
}

.primary-font-bold {
  font-family: $base-font-family-bold !important;
}

.secondary-font {
  font-family: $secondary-font-family !important;
  font-weight: 400;
}

.secondary-font-bold {
  font-family: $secondary-font-family-bold !important;
}

.text-color-primary {
  color: $primary-color;

  &:hover {
    color: $primary-color;
  }
}

.header-color {
  color: $header-color;
}

.text-color-secondary {
  color: $secondary-color;

  &:hover {
    color: $secondary-color;
  }
}

.text-color-thridly {
  color: $third-color;

  &:hover {
    color: $third-color;
  }
}

.text-color-grey {
  color: $medium-grey;
}

.label-color {
  color: #5E5E5E;
}

.text-color-alert {
  color: $alert;
}

.text-color-warning {
  color: $warning;
}

.text-color-success {
  color: $success;
}

.text-orange {
  color: $hover-color;
}

.caption-grey {
  color: $caption-grey;
}