.modal-open {
  overflow: hidden;

  .account-nav-bar {
    z-index: 1;
  }
}

.mdc-dialog__surface {
  border-radius: 19px!important;
}

.cdk-global-scrollblock {
  position: unset !important;
}

.cdk-overlay-dark-backdrop {
  background: rgb(0 0 0 / 42%) !important;
}

.cdk-overlay-pane {
  max-width: 100% !important;
}

.mat-dialog-container {
  padding: 0 !important;
  box-shadow: none !important;
  background: transparent !important;
  border-radius: 19px !important;
}

.modal {
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 1;
  transition: opacity, visibility 0.3s 0.12s;

  .modal-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (#{$larger-than-desktop}) {
      width: 100%;
    }
  }

  .modal-wrapper,
  .model-container {
    z-index: 9999;
    background-color: #fff;
    border-radius: 19px;
    max-height: 90vh;
    overflow: hidden;
    width: 700px;
    padding: 24px !important;
    margin: auto;

    @media (max-width: $ipad-min-width-minus) {
      width: 600px;
    }

    @media (max-width: $phablet-min-width-minus) {
      padding: 16px !important;
      max-width: 320px;
    }

    @media (max-width: $mobile-min) {
      max-width: 288px;
    }

    .modal-header {
      width: 100%;
      padding: 16px 12px;
      font-size: 28px;
      line-height: 36px;
      font-weight: bold;
      color: #090909;
      background-color: transparent;
      text-align: center;
      position: relative;

      @media (max-width: $phablet-min-width-minus) {
        font-size: 22px;
        line-height: 32px;
      }

      .modal-heading {
        font-size: 28px;
        line-height: 36px;
        width: 100%;
        margin-bottom: 0;
        font-weight: bold;

        @media (max-width: $phablet-min-width-minus) {
          font-size: 22px;
          line-height: 32px;
        }
      }

      .modal-close {
        background: none;
        background-color: transparent;
        top: -10px;
        right: -1px;
        position: absolute;
        border: 0;
        cursor: pointer;

        .icon-close {
          color: $primary-color;
          font-size: 30px;
          font-weight: bold;
        }
      }
    }

    .modal-body {
      .modal-content {
        padding: 8px 12px;
        text-align: center;

        @media (max-width: $phablet-min-width-minus) {
          padding: 12px 20px;
        }

        .modal-actions {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin: 0;
          margin-top: 16px;
          margin-bottom: 12px;
          padding: 0;
          text-align: center;

          @media (max-width: $phablet-min-width-minus) {
            margin-top: 20px;
          }
          .primary {
            margin-right: 16px;
          }

          .transparent {
            margin-right: 0;
          }
        }
      }
    }

    &.add-number-modal {
      input {
        max-width: 300px;
      }

      .modal-actions {
        margin: 0 !important;

        .cancel {
          display: none;
        }

        .primary {
          margin-right: 0 !important;
        }
      }
    }

    &.skip-tmo-modal {
      width: 100%;
      max-width: 700px;

      b {
        font-family: $base-font-family-bold;
      }

      @media (max-width: $desktop-min-width-minus) {
        width: 600px;
      }

      @media (max-width: $tablet-min-width-minus) {
        width: 578px;
      }

      @media (max-width: $phablet-min-width-minus) {
        max-width: 575px;
        width: 100%;
      }

      .modal-header {
        padding: 15px 0;

        .modal-heading {
          font-size: rem-calc(32px);
          line-height: 40px;

          @media (max-width: $phablet-min-width-minus) {
            font-size: rem-calc(24px);
            line-height: 32px;
          }
        }
      }

      .modal-body {
        .modal-content {
          padding: 0 32px 32px;

          .row {
            padding: 0;

            .tmo-skip-content {
              .desc {
                font-size: rem-calc(20px);
                line-height: 32px;
                margin: 0 auto;
                margin-bottom: 16px;

                @media (max-width: $phablet-min-width-minus) {
                  font-size: rem-calc(16px);
                  line-height: 24px;
                }

                &.mobile-width {
                  @media (max-width: 490.9px) {
                    max-width: 330px;
                  }

                  @media (max-width: 384.9px) {
                    max-width: 210px;
                  }
                }

                .tooltip {
                  bottom: 1.5px;
                  cursor: pointer;

                  .info {
                    width: 24px;
                    height: 24px;
                    margin-left: 4px;

                    @media (max-width: $phablet-min-width-minus) {
                      width: 16px;
                      height: 16px;
                    }
                  }

                  .tooltiptext {
                    width: 270px;
                    top: 52px;
                    left: -145px;
                    height: fit-content;
                    padding: 16px;
                    background: #dad0dc;
                    box-shadow: 0px 4px 15px rgb(206 211 214 / 60%);
                    border-radius: 10px;
                    font-size: rem-calc(14px);
                    line-height: 24px;

                    @media (max-width: $phablet-min-width-minus) {
                      width: 200px;
                      padding: 12px;
                      text-align: center;
                      top: 45px;
                      left: -80px;
                    }

                    p {
                      margin-bottom: 0;
                    }

                    img {
                      width: 17px;
                      height: 9.32px;
                      position: absolute;
                      top: -8px;
                      left: 233px;

                      @media (max-width: $phablet-min-width-minus) {
                        left: 163.5px;
                      }
                    }
                  }
                }

                &.note {
                  font-size: rem-calc(16px);
                  line-height: 24px;
                  font-weight: 400;
                  max-width: 514px;

                  @media (max-width: $phablet-min-width-minus) {
                    font-size: rem-calc(14px);
                    margin-bottom: 20px;
                  }
                }
              }

              .actions {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 16px;
                margin-bottom: 16px;

                @media (max-width: $phablet-min-width-minus) {
                  flex-direction: column;
                }

                button {
                  font-size: rem-calc(20px);
                  line-height: 20px;
                  font-weight: 700;
                  width: 247px;
                  height: 36px;
                  padding: 0;

                  @media (max-width: $phablet-min-width-minus) {
                    font-size: rem-calc(16px);
                    line-height: 16px;
                    width: 228px;
                    height: 32px;
                  }
                }
              }

              a {
                font-size: rem-calc(20px);
                line-height: 20px;
                font-family: $secondary-font-family-bold;

                @media (max-width: $phablet-min-width-minus) {
                  font-size: rem-calc(16px);
                  line-height: 16px;
                }
              }
            }
          }
        }
      }
    }

    &.confirm-password-modal {
      .modal-body {
        .modal-content {
          padding: 0 32px;
          margin-top: 0px;

          .label-msg {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 10px;

            @media (max-width: $phablet-min-width-minus) {
              font-size: 16px;
              line-height: 18px;
            }
          }

          input {
            max-width: 300px;
          }
        }

        .modal-actions {
          margin-top: 0 !important;

          .button {
            width: 130px;
            padding: 6px 12px;
          }
        }
      }
    }

    &.esim-replacement {
      .modal-body {
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-height: 150px;

          .re-captcha--resizing {
            min-height: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 12px;
            margin-bottom: 18px;

            @media (max-width: 370px) {
              padding-left: 36px;
            }
          }
        }

        .button {
          width: 137px;
          padding: 8px;
        }
      }
    }

    &.five-toggle-modal {
      .modal-body {
        .modal-content {
          padding: 32px;
          padding-top: 12px;

          .row {
            padding: 0;
            margin-bottom: 0;

            div {
              max-width: 510px;
              width: 100%;

              p {
                margin-bottom: 0;
                font-size: rem-calc(16px);
                line-height: 18px;
                font-weight: 400;
              }
            }
          }

          .modal-actions {
            a {
              font-family: $lato-font-family;
              font-size: rem-calc(16px);
              font-weight: 700;

              .arrow {
                margin-left: 16px;
              }
            }
          }
        }
      }
    }

    &.change-plan-modal {
      .modal-body {
        overflow-x: visible;
        overflow-y: visible;

        .modal-content {
          p {
            margin-bottom: 8px;
          }
          @media (max-width: $phablet-min-width-minus) {
            padding: 0 !important;
          }
        }
      }

      .modal-actions {
        flex-direction: column;
        gap: 16px;

        .button {
          width: 100% !important;
          max-width: 311px;
          padding: 8px !important;

          &.primary {
            margin-right: 0 !important;
          }

          @media (max-width: $phablet-min-width-minus) {
            padding: 12px !important;
          }
        }
      }

      @media (max-width: $tablet-min-width) {
        .modal-body {
          overflow-y: auto;
        }
      }

      &.expiry {
        .modal-actions {
          .button {
            &.primary {
              display: none;
            }
          }
        }
      }
    }

    &.select-addon-modal {
      .modal-content {
        .row {
          display: flex;
          margin: 0;
          justify-content: center;

          &:first-child {
            display: none;
          }
        }

        .modal-actions {
          @media (max-width: $phablet-min-width-minus) {
            flex-direction: column;
          }

          .button {
            width: 250px !important;

            &.primary {
              @media (max-width: $phablet-min-width-minus) {
                margin-bottom: 12px;
                margin-right: 0 !important;
              }
            }
          }
        }
      }
    }

    &.edit-payment-modal {
      width: 100%;

      @media (min-width: 1024px) {
        width: 870px;
      }

      .modal-header {
        width: 100%;
        text-align: left;
      }

      .modal-body {
        .modal-content {
          text-align: left;

          .content {
            display: flex;
            text-align: left;

            .note {
              font-size: rem-calc(17px);
              line-height: 26px;

              .link {
                color: $primary-color;
                cursor: pointer;
              }
            }

            @media (max-width: 639.9px) {
              .note {
                font-weight: normal;
              }
            }
          }

          .form-section {
            display: inline-block;
            text-align: left;
            width: 100%;
            margin-top: 13px;

            .button {
              margin-top: 14px !important;
              margin-bottom: 44px !important;
            }

            input {
              width: auto;
            }

            label {
              display: inline;
              font-size: rem-calc(15px);
              line-height: 17px;
              font-weight: bold;
              padding-right: 10px;
            }

            .validation-message {
              display: table-cell;
              text-transform: inherit;
              padding: 10px 0;
            }

            .required-field {
              color: #d94a47;
            }

            .first-row {
              text-align: left;
              margin-bottom: 20px;

              .cardNumber-section {
                width: 55%;
                display: inline-block;

                .modal-label {
                  font-weight: bold;
                  font-size: rem-calc(15px);
                  line-height: 17px;
                }
              }

              .name-section {
                width: auto;
                display: inline-block;
              }
            }

            .second-row {
              text-align: left;

              .expiry-section {
                width: 63%;
                display: inline-block;

                .sperator {
                  font-size: rem-calc(28px);
                  position: relative;
                  top: 5px;
                  padding: 0 4px;
                }

                .select {
                  width: 25%;
                  margin-right: 0;

                  select {
                    height: 30px;
                    width: 120px;
                    padding: 4px 10px;
                    font-size: rem-calc(13px);

                    @media (min-width: 1200px) {
                      width: 126px;
                    }
                  }

                  .select-arrow {
                    top: 12px;
                  }
                }
              }

              .cvv-section {
                width: 36%;
                display: inline-block;
              }
            }

            &.address-form {
              .title {
                font-family: $base-font-family-bold;
                font-size: rem-calc(15px);
                line-height: 17px;
                margin-bottom: 25px;
              }

              .populated {
                .address {
                  font-size: rem-calc(17px);
                  line-height: 22px;
                  margin-bottom: 7px;

                  .edit {
                    padding-left: 10px;
                    color: $primary-color;
                    cursor: pointer;

                    img {
                      background-color: $primary-color;
                      padding: 5px;
                      border-radius: 52%;
                    }
                  }
                }

                @media (max-width: 639.9px) {
                  .address {
                    .edit {
                      display: block;
                      margin-top: 14px !important;
                      padding-left: 0 !important;
                    }
                  }
                }
              }

              .addressForm {
                margin-bottom: 25px;

                input {
                  padding: 5px 10px;
                }

                .addressName-section {
                  width: 50%;
                  display: inline-block;

                  input {
                    width: 277px;
                  }
                }

                .address-section {
                  width: 48%;
                  display: inline-block;

                  .ngui-auto-complete-wrapper {
                    display: inline;

                    input {
                      width: 250px;
                    }
                  }
                }

                .details-section {
                  display: inline-block;
                  width: 53%;

                  .state-section {
                    display: inline-block;

                    input {
                      width: 120px;
                    }
                  }

                  .city-section {
                    display: inline-block;
                    padding-left: 5px;

                    input {
                      width: 120px;
                    }
                  }
                }

                .postal-section {
                  display: inline-block;
                }
              }
            }

            &.modal-actions {
              display: contents;

              .button {
                width: 220px;
                padding: 11px;
                font-size: 18px;
              }

              @media (max-width: 639.9px) {
                display: inline-block;
                margin: auto;
                text-align: center;
                margin-bottom: 12px;
              }
            }

            @media (max-width: 1024.9px) {
              .first-row {
                .cardNumber-section {
                  width: 100%;
                  margin-bottom: 20px;
                }

                .name-section {
                  width: auto;

                  input {
                    width: 410px;
                  }
                }
              }

              .second-row {
                .expiry-section {
                  width: 100%;
                  margin-bottom: 6px;

                  label {
                    padding-right: 27px;
                  }

                  .select {
                    width: 200px;

                    @media (min-width: 640px) and (max-width: 688.9px) {
                      width: 47%;
                    }

                    select {
                      width: 200px;

                      @media (min-width: 640px) and (max-width: 688.9px) {
                        width: 100%;
                      }
                    }
                  }
                }

                .cvv-section {
                  width: auto;

                  label {
                    margin-right: 58px;
                  }
                }
              }

              &.address-form {
                .addressForm {
                  .addressName-section {
                    width: 100%;
                    margin-bottom: 20px;

                    label {
                      margin-right: 46px;
                    }

                    input {
                      width: 410px;
                    }
                  }

                  .address-section {
                    width: 100%;

                    .ngui-auto-complete-wrapper {
                      input {
                        width: 412px;
                      }
                    }
                  }

                  .details-section {
                    width: 100%;
                    margin-bottom: 20px;

                    .state-section {
                      label {
                        margin-right: 49px;
                      }

                      input {
                        width: 167px;
                      }
                    }

                    .city-section {
                      label {
                        margin-left: 18px;
                        margin-right: 18px;
                      }

                      input {
                        width: 167px;
                      }
                    }
                  }

                  .postal-section {
                    width: 100%;

                    label {
                      margin-right: 25px;
                    }
                  }
                }
              }
            }

            @media (max-width: 833.9px) {
              label {
                display: block;
              }

              &.address-form {
                .addressForm {
                  .details-section {
                    .city-section {
                      label {
                        margin-left: 0;
                      }
                    }
                  }
                }
              }
            }

            @media (max-width: 639.9px) {
              label {
                font-size: rem-calc(17px);
                line-height: 20px;
              }

              .first-row {
                .name-section {
                  input {
                    width: 100%;
                  }
                }
              }

              .second-row {
                .expiry-section {
                  .select {
                    width: 45%;

                    select {
                      width: 100%;
                    }
                  }
                }

                .cvv-section {
                  input {
                    width: 100%;
                  }
                }
              }

              &.address-form {
                .addressForm {
                  .addressName-section {
                    input {
                      width: 100%;
                    }
                  }

                  .address-section {
                    .ngui-auto-complete-wrapper {
                      input {
                        width: 100%;
                      }
                    }
                  }

                  .details-section {
                    .city-section {
                      display: block;
                      padding-left: 0;
                      margin-top: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.information-message-modal {
      .modal-content {
        .row {
          width: 100%;

          .msg {
            font-size: 16px;
            line-height: 18px;

            @media (max-width: $phablet-min-width-minus) {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
    }

    &.timer-alert-modal {
      max-width: 700px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      @media(max-width: $phablet-min-width-minus) {
        max-width: 100%;
      }

      b {
        font-weight: bold;
        font-family: $base-font-family-bold;
      }

      img {
        width: 100px;
        height: 100px;
        margin-top: 30px;

        @media(max-width: $phablet-min-width-minus) {
          width: 80px;
          height: 80px;
        }
      }

      .modal-header {
        padding: 0;

        .modal-heading {
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 24px;

          @media(max-width: $phablet-min-width-minus) {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 20px;
          }
        }
      }

      .desc {
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        font-weight: normal;
        max-width: 628px;

        @media(max-width: $phablet-min-width-minus) {
          max-width: 100%;
        }

        &.last {
          margin-top: 8px;
        }
      }

      .note {
        color: #F58E25;
        ;
        font-size: 14px;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 24px;
      }

      .modal-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;

        @media(max-width: $phablet-min-width-minus) {
          margin-bottom: 32px;
          flex-direction: column;
        }

        button {
          min-width: 177px;
        }

        .left {
          margin-right: 16px;

          @media(max-width: $phablet-min-width-minus) {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }

    &.success-swap-modal {
      .modal-close {
        @media (max-width: $phablet-min-width-minus) {
          top: -15px;
        }
      }

      .modal-content {
        .subHeader {
          font-size: rem-calc(17px);
          line-height: 25px;
          font-family: $base-font-family-bold;
          font-weight: bold;
        }

        .details {
          font-size: rem-calc(17px);
          line-height: 25px;
          font-family: $base-font-family;
        }
      }
    }

    &.SIM-replacement-modal {
      .modal-content {
        padding: 0;
        overflow: hidden;

        .question {
          font-size: rem-calc(25px);
          font-family: $secondary-font-family;
          font-weight: 500;
          line-height: 33px;
        }

        .number {
          font-size: rem-calc(30px);
          font-family: $base-font-family-bold;
          font-weight: bold;
          line-height: 34px;
        }

        @media (max-width: 640px) {
          .question {
            font-size: rem-calc(20px);
            line-height: 27px;
          }

          .number {
            font-size: rem-calc(25px);
            line-height: 27px;
          }
        }
      }
    }

    &.Sim-replacement-iccid-modal {
      .modal-content {
        overflow: hidden;
        margin-top: 0 !important;
        padding: 0 !important;

        .row {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        &.captcha {
          margin: 20px auto 0;
        }

        input {
          width: 240px;
          height: 40px;
          padding: 0 8px;
        }

        .label {
          font-size: rem-calc(16px);
          font-family: $base-font-family;
          margin-bottom: 15px;
          line-height: 17px;
          margin-top: 0px;
          text-align: center;
          padding: 0 10px;
        }

        a {
          font-size: rem-calc(16px);
          font-family: $secondary-font-family-bold;
        }
      }

      .modal-actions {
        .button {
          width: 116px !important;
        }
      }
    }

    &.confirm-swap-modal {
      .modal-content {
        padding: 0;

        p {
          margin-bottom: 5px;
          font-size: rem-calc(18px);
          font-family: $base-font-family;
          line-height: 28px;

          b {
            font-family: $base-font-family-bold;
          }
        }

        b {
          font-family: $base-font-family-bold;
        }

        .subhead {
          font-size: 22px;
          line-height: 24px;
        }

        .question {
          font-size: rem-calc(18px);
          font-family: $base-font-family;
          line-height: 28px;
          margin-bottom: 0;
        }

        .confirm {
          font-size: rem-calc(22px);
          font-family: $secondary-font-family;
          line-height: 28px;
        }

        .details {
          font-size: rem-calc(13px);
          font-family: $base-font-family;
          line-height: 15px;
        }

        @media (max-width: 640px) {
          overflow: hidden;

          .question {
            font-size: rem-calc(18px);
            line-height: 20px;
          }

          .confirm {
            font-size: rem-calc(18px);
            line-height: 20px;
          }
        }

        @media (max-width: 425.9px) {
          b {
            display: block;
            margin-top: 5px;
          }
        }
      }

      .modal-content>* {
        padding: 0.5em 2em;
      }
    }

    &.confirm-esim {
      .modal-actions {
        .button {
          width: 150px !important;
        }
      }

      .modal-body {
        margin-top: 0px;

        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .info {
            font-size: rem-calc(20px);
            line-height: 32px;
            margin-bottom: 8px;
          }

          .focus {
            font-size: rem-calc(22px);
            line-height: 32px;
            margin-top: 4px;
            font-weight: bold;
          }
        }

        .row {
          margin-bottom: 0;
        }

        @media (max-width: $phablet-min-width-minus) {
          .content {
            .info {
              font-size: rem-calc(16px);
              line-height: 24px;
              margin-bottom: 4px;
            }

            .focus {
              font-size: rem-calc(18px);
              line-height: 28px;
            }
          }
        }
      }
    }

    &.usp-pop-up-modal {
      @media (min-width: 730px) {
        width: 700px !important;
      }

      @media (max-width: 888.9px) {
        overflow: scroll;
      }

      .modal-header {
        text-align: left;
      }

      .modal-actions {
        justify-content: flex-start !important;

        @media (max-width: 729.9px) {
          flex-direction: column;
          align-items: flex-start !important;
          margin-top: 0 !important;
        }

        .button {
          width: 250px !important;

          &.primary {
            @media (max-width: 729.9px) {
              margin-bottom: 12px;
              margin-right: 0 !important;
            }
          }

          &.transparent {
            @media (min-width: 1200px) {
              margin-left: 48px;
            }

            @media (min-width: 730px) and (max-width: 1199.9px) {
              margin-left: 31px;
            }
          }
        }
      }

      .modal-body {
        overflow: hidden;
        max-height: unset;

        .row {
          width: 100%;
        }

        .modal-content {
          text-align: left;

          .flex-container {
            display: flex;

            @media (max-width: 729.9px) {
              flex-direction: column;
            }
          }

          .pop-header {
            display: inline-block;
            padding-right: 2%;
            width: 100%;
            height: 82px;
            font-family: $base-font-family;
            font-size: rem-calc(17px);
            line-height: 1.18;

            @media (max-width: 729.9px) {
              max-width: 290px;
              max-width: 290px;
              height: 100%;
              margin-bottom: 20px;
              line-height: 14px;
            }
          }

          .sub-padding {
            padding-left: 18px;
          }
        }
      }
    }

    &.usp-pop-up-modal2 {
      .modal-header {
        text-align: left;
      }

      .modal-actions {
        justify-content: flex-start !important;

        @media (max-width: 729.9px) {
          flex-direction: column;
          align-items: flex-start !important;
        }

        .button {
          width: 250px !important;

          &.primary {
            @media (max-width: 729.9px) {
              margin-bottom: 12px;
            }
          }
        }
      }

      .modal-body {
        overflow: hidden;
        max-height: unset;

        .modal-content {
          text-align: left;
          padding-top: 3px;

          .row {
            width: 100%;
          }

          .flex-container {
            display: flex;
            flex-direction: column;
          }

          .pop-header1 {
            display: inline-block;
            padding-right: 52px;
            width: 560px;
            font-family: $base-font-family;
            font-size: rem-calc(17px);
            line-height: 1.18;
            margin-bottom: 20px;

            @media (max-width: 729.9px) {
              width: 100%;
              margin-bottom: 15px;
            }
          }

          .pop-header2 {
            display: inline-block;
            padding-right: 52px;
            width: 560px;
            font-family: $base-font-family;
            font-size: rem-calc(17px);
            line-height: 1;

            @media (max-width: 729.9px) {
              width: 100%;
            }
          }

          .sub-padding {
            padding-left: 18px;
          }
        }
      }
    }

    &.custom-modal-view-updates {
      .modal-header {
        text-align: left;
      }

      .modal-body {
        .modal-content {
          padding-top: 0;
          text-align: left;

          .row {
            width: 100%;

            .track-font-size {
              font-size: rem-calc(21px);

              @media (max-width: $desktop-min-width-minus) {
                font-size: rem-calc(18px);
              }
            }

            .date {
              @media (max-width: $desktop-min-width-minus) {
                font-size: rem-calc(16px);
              }
            }

            .display-flex {
              display: flex;
              width: 100%;

              @media (max-width: $desktop-min-width-minus) {
                font-size: rem-calc(16px);
              }

              .border-right {
                width: 124px;
                border-right: 1px solid;
                height: 40px;
              }

              .left-margins {
                margin-left: 20px;

                @media (max-width: $phablet-min-width-minus) {
                  max-width: 160px;
                  width: 100%;
                }
              }
            }
          }

          button {
            display: none;
          }
        }
      }
    }

    &.custom-modal-voucher-results {
      .modal-content {
        .message {
          font-size: rem-calc(22px);
          line-height: 26px;
          font-family: $base-font-family;
          color: $black;
          font-weight: normal;

          &.balance {
            font-weight: bold;
            color: $black;

            .text-color-alert {
              color: $black;
            }
          }
        }
      }

      @media (max-width: 640px) {
        .modal-content {
          .message {
            font-size: rem-calc(17px);
            line-height: 20px;
          }
        }
      }
    }

    &.change-ebb-plan-modal {
      .row {
        width: 100%;
      }
      .modal-actions{
        display: flex;
        gap: 14px;
        @media(max-width: $phablet-min-width-minus) {
          flex-direction: column;
        }
        .button {
          width: unset;
        }
        .primary {
          margin-right: 0px!important;
        }
      }
    }

    &.wifi-result-modal {
      .modal-body {
        .modal-content {
          .row {
            width: 100%;
            font-size: rem-calc(14px);
          }

          .button {
            width: 133px !important;
          }
        }
      }
    }

    &.successful-activation-modal {
      .modal-body {
        .row {
          font-size: rem-calc(17px);
          line-height: 20px;
          width: 100%;
        }
      }

      @media (max-width: $phablet-min-width) {
        .modal-body {
          .row {
            font-size: rem-calc(15px);
            line-height: 17px;
          }
        }
      }
    }

    &.pending-confirmation {
      .modal-actions {
        @media (max-width: $phablet-min-width-minus) {
          flex-direction: column;
        }

        .button {
          width: 200px !important;

          &.primary {
            @media (max-width: $phablet-min-width-minus) {
              margin-bottom: 12px;
              margin-right: 0 !important;
            }
          }
        }
      }

      .modal-body {
        .modal-content {
          .row {
            padding: 0;
          }

          .note {
            font-size: rem-calc(18px);
            line-height: 28px;
            margin-bottom: 5px;
            font-weight: 400;
          }
        }
      }
    }

    &.send-receipt-email-modal {
      .modal-actions {
        .button {
          width: 150px !important;
        }
      }

      .modal-content {
        .row {
          width: 100%;

          .details {
            font-size: rem-calc(18px);
            line-height: 25px;
            font-family: $base-font-family-bold;
            max-width: 540px;
            text-align: center;
            margin: 0 auto;
          }
        }
      }
    }

    &.acp-modal-exists {
      .modal-actions {
        .button {
          width: 220px !important;
        }

        .primary {
          margin-right: 0 !important;
        }
      }
    }

    &.ebb-validation {
      .modal-content {
        display: flex;
        flex-direction: column;

        .modal-actions {
          .button {
            width: 120px;
            padding: 10px;
          }
        }
      }
    }

    &.compatibility-help-modal-IME {
      @media (min-width: 641px) and (max-width: 725.9px) {
        overflow: scroll;
      }

      .modal-body {
        .modal-content {
          .row {
            width: 100%;
            padding: 0;
          }

          .description-content {
            margin-bottom: 22px;
            max-width: 558px;
            font-size: rem-calc(18px);
            font-family: $base-font-family;
            line-height: 1.56;
            margin-top: 18px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
          }

          .intro {
            margin-top: 16px;
            margin-bottom: 30px;

            @media (min-width: 641px) and (max-width: 1024.9px) {
              margin-top: 8px;
              font-size: rem-calc(15px);
            }

            @media (max-width: 640px) {
              font-size: rem-calc(14px);
            }
          }

          .note-dial {
            margin-bottom: 9px;

            @media (max-width: 1024.9px) {
              font-size: rem-calc(15px);
            }

            @media (max-width: 640px) {
              font-size: rem-calc(13px);
            }
          }

          .p-nowrap {
            @media (min-width: 1025px) {
              white-space: nowrap;
            }

            @media (max-width: 1024.9px) {
              font-size: rem-calc(15px);
            }

            @media (max-width: 640px) {
              font-size: rem-calc(13px);
            }

            margin-bottom: 0px;
          }

          .menu-margins {
            margin-top: 9px;
            margin-bottom: 9px;
            width: 100%;

            @media (max-width: 1024.9px) {
              font-size: rem-calc(15px);
            }

            @media (max-width: 640px) {
              font-size: rem-calc(13px);
            }
          }

          .button {
            width: 149.8px;
            margin-bottom: 20px !important;
          }
        }
      }

      @media (max-width: $phablet-min-width) {
        .modal-body {
          .modal-content {
            .description-content {
              font-size: rem-calc(15px);
              line-height: 22px;
            }

            .list-content {
              padding: 5px;
              font-size: rem-calc(13px);

              li {
                span {
                  font-size: rem-calc(13px);
                  line-height: 22px;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }

    &.usp-pop-up-modal3 {
      @media (max-width: $phablet-min-width-minus) {
        overflow: scroll;
      }

      .modal-body {
        overflow: hidden;
        max-height: unset;

        .modal-content {
          text-align: center;

          .row {
            padding: 0;
            width: 100%;
          }

          .flex-container {
            display: flex;
            flex-direction: column;

            @media (max-width: $phablet-min-width-minus) {
              width: 272px;
              height: 197px;
            }
          }

          .pop-header1 {
            display: inline-block;
            padding-right: 0px;
            width: 100%;
            font-family: $base-font-family;
            font-size: 18px;
            line-height: 0;
            margin-bottom: 16px;

            @media (max-width: $phablet-min-width-minus) {
              width: 100%;
              margin: 0;
              margin-bottom: 15px;
              font-size: 16px;
              line-height: 24px;
              max-width: 235px;
              text-align: left;
            }
          }

          .pop-header2 {
            display: inline-block;
            padding-right: 0px;
            width: 560px;
            font-family: $base-font-family;
            font-size: 18px;
            line-height: 28px;
            margin-top: 0px;
            margin-left: 14%;
            text-align: left;
            margin-bottom: 0;

            @media (max-width: $phablet-min-width-minus) {
              font-size: 16px;
              line-height: 24px;
              max-width: 235px;
              margin-left: 2%;
            }

            li {
              margin-bottom: 8px;
            }

            @media (max-width: $phablet-min-width-minus) {
              width: 290px;
            }
          }
        }
      }
    }

    &.big-button {
      .modal-actions {
        .button {
          width: 360px !important;
        }

        .primary {
          margin-right: 0 !important;
        }
      }
    }

    &.iccid-info-modal {
      width: 94% !important;
      max-width: 100% !important;

      .row {
        padding: 10px;
        width: 100%;
      }

      .modal-actions {
        @media (max-width: 540.9px) {
          margin-top: 140px !important;
          margin-bottom: 50px !important;
        }

        .button {
          width: 180px !important;
        }

        .primary {
          margin-right: 0 !important;
        }
      }

      .text-content-holder {
        margin-bottom: 25px;

        .text {
          font-size: rem-calc(18px);
          margin-bottom: 20px;
          line-height: 21px;
          font-family: $base-font-family;
          display: inline-block;
          max-width: 581px;
        }
      }

      .image-container {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
        min-height: max-content;
        justify-content: left;
        padding-left: 0px;

        .pointer-holder {
          position: absolute;
          height: 120%;
          background-color: $black;
          width: 1px;
          z-index: 2;
          left: 248px;
          top: -49px;
          color: black;
          font-weight: bold;

          @media (min-width: 426px) {
            transform: rotate(90deg);
            color: black;
            font-weight: bold;
          }
        }

        img {
          width: 221.7px;
          height: 138.7px;
          left: 0;
        }

        .image-pointer {
          font-size: 0.9rem;
          line-height: 22px;
          position: absolute;
          top: 27px;
          width: 208px;
          text-align: left;
          left: 346px;
          font-weight: bold;
          color: black;

          @media (min-width: 834px) {
            right: 0;
            font-weight: bold;
          }
        }

        .rect {
          background-color: #f2e8f4;
          width: 90.7px;
          height: 13.3px;
          position: absolute;
          left: 96.3px;
          text-align: left;
          font-size: rem-calc(7px);

          &.rect1 {
            top: 28.4px;
          }

          &.rect2 {
            top: 46.4px;
          }

          &.rect3 {
            top: 73px;
          }

          &.rect4 {
            top: 78px;
            display: none;
          }
        }

        .big-rect {
          background-color: #f2e8f4;
          height: 9.4px;
          width: 56.7px;
          position: absolute;
          left: 325px;
          font-size: rem-calc(7px);
          text-align: left;

          &.rect1 {
            top: 61px;
          }

          &.rect2 {
            top: 72px;
          }

          &.rect3 {
            top: 83px;
          }

          &.rect4 {
            display: none;
            top: 94px;
          }
        }

        @media (max-width: 833.9px) {
          margin-left: 40px;

          .image-pointer {
            top: 25px;
            width: 230px;
            font-size: 0.8em;
            text-align: center;
          }
        }

        @media (max-width: 825px) {
          .image-pointer {
            width: 188px;
            left: 246px;
            top: 25px;
          }

          .pointer-holder {
            top: -16px;
            height: 73%;
            left: 189px;
          }
        }

        @media (max-width: 689px) {
          .image-pointer {
            width: 151px;
            left: 246px;
            top: 25px;
          }

          .pointer-holder {
            top: -16px;
            height: 73%;
            left: 189px;
          }
        }

        @media (max-width: 540.9px) {
          margin-left: 0px !important;
          justify-content: center !important;

          .pointer-holder {
            top: 26px;
            height: 100%;
            transform: none;
          }

          .image-pointer {
            top: 186px;
            width: 100%;
            max-width: 340px;
            left: 0px;
            position: absolute;
          }

          .button {
            margin-top: -64px;
          }

          .rect {
            left: 121px;
          }
        }
      }
    }

    &.activation-info-modal {
      .modal-actions {
        .button {
          width: 120px !important;
        }
      }

      .modal-content {
        padding: 12px 13px;

        .row {
          display: block;

          .text-content-holder {
            width: 100%;
            max-width: 609px;
            margin: 0 auto;
            margin-bottom: 30px;
            font-size: rem-calc(18px);

            @media (max-width: 833.9px) {
              max-width: 404px;
            }

            @media (max-width: 633.9px) {
              max-width: 296px;
              font-size: rem-calc(17px);
            }
          }

          .image-container {
            .activation-image {
              width: 288.9px;
              height: 181.9px;

              @media (max-width: 633.9px) {
                height: 144px;
                width: 230px;
              }
            }
          }
        }
      }
    }

    &.successPhoneModal {
      .modal-actions {
        .button {
          width: 180px !important;
        }

        .primary {
          margin-right: 0 !important;
        }
      }

      .modal-body {
        .modal-content {
          .row {
            .success-message {
              text-align: center;

              .message {
                font-size: rem-calc(17px);
                line-height: 24px;
                margin-bottom: 20px;
                font-weight: bold;
              }

              .note {
                font-weight: bold;
                font-size: rem-calc(14px);
                line-height: 22px;
              }
            }
          }
        }
      }
    }

    &.unlockedModal {
      .modal-actions {
        margin-top: 0 !important;
        margin-bottom: 20px !important;

        .button {
          width: 180px !important;
        }

        .primary {
          margin-right: 0 !important;
        }
      }

      .modal-body {
        .modal-content {
          .row {
            text-align: left;

            .question-section {
              .question {
                font-weight: bold;
                font-size: rem-calc(14px);
                line-height: 22px;
                margin-bottom: 0;
              }

              .answer {
                margin-bottom: 20px;
                font-size: rem-calc(14px);
                line-height: 22px;
              }
            }
          }
        }
      }
    }

    &.invalid-card {
      .modal-body {
        .modal-content {
          .description {
            font-size: rem-calc(18px);
            line-height: 28px;
            margin-bottom: 16px;
            color: #262626;
          }

          .list {
            list-style-position: inside;
            padding-inline-start: 0;

            li {
              font-size: rem-calc(18px);
              line-height: 28px;
              margin-bottom: 8px;
              color: #262626;
            }
          }

          .note {
            margin-bottom: 4px;
            font-size: rem-calc(20px);
            line-height: 28px;
            font-weight: 400;
          }
        }
      }
    }

    &.duplicate-result-modal {
      &.error {
        .modal-header {
          .modal-heading {
            color: #d22119;
          }
        }
      }

      .modal-actions {
        .primary {
          margin-right: 0 !important;
        }
      }

      .modal-body {
        .modal-content {
          display: flex;
          flex-direction: column;
        }
      }
    }

    &.archived-plan {
      .modal-actions {
        .button {
          width: 145px !important;
        }

        .primary {
          margin-right: 0 !important;
        }
      }

      .modal-body {
        .modal-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .content {
            .info {
              font-size: rem-calc(18px);
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    &.voucher-info-modal {
      .modal-actions {
        margin-bottom: 50px !important;

        @media (max-width: 1024.9px) {
          margin-top: 140px !important;
        }

        @media (max-width: 639.9px) {
          margin-top: 160px !important;
        }

        .button {
          width: 145px !important;
        }

        .primary {
          margin-right: 0 !important;
        }
      }

      .row {
        width: 100%;
      }

      .text-content-holder {
        font-size: rem-calc(22px);
        margin-bottom: 20px;
        line-height: 26px;
        font-family: $base-font-family;
        display: inline-block;
        min-height: max-content;

        @media (max-width: 1025px) {
          font-size: rem-calc(17px);
          line-height: 20px;
        }
      }

      .image-container {
        position: relative;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        min-height: max-content;

        .pointer-holder {
          position: absolute;
          height: 50%;
          background-color: $black;
          width: 1px;
          z-index: 2;
          top: 222px;
          left: 55%;

          @media (#{$larger-than-desktop}) {
            left: 355px;
          }
        }

        img {
          width: 182.4px;
          height: 263px;
          left: 0;
        }

        .image-pointer {
          font-size: rem-calc(17px);
          line-height: 22px;
          display: inline-block;
          max-width: 280px;
          position: absolute;
          top: 375px;
        }

        @media (#{$larger-than-desktop}) {
          justify-content: left;
          padding-left: 80px;

          .pointer-holder {
            height: 80%;
            transform: rotate(90deg);
            top: 100px;
            background-color: $input-border;
          }

          .image-pointer {
            max-width: 225px;
            top: 200px;
            right: 36px;
            font-size: rem-calc(15px);
          }
        }
      }
    }

    &.autoPay-help-modal {
      .modal-header {
        text-align: left;
      }

      .modal-content {
        text-align: left;
        font-size: rem-calc(16px);

        .description-content {
          margin-bottom: 20px;
        }

        .payment-sub {
          margin-bottom: 20px;
        }

        @media (#{$larger-than-phablet}) {
          font-size: rem-calc(17px);
        }
      }
    }

    &.ios-setup-modal {
      .modal-header {
        .modal-heading {
          text-align: center !important;
          margin: 0 auto !important;
        }
      }

      height: 100%;
    }

    &.tyy-message-modal {
      .modal-content {
        width: 100%;
        text-align: center;

        .info {
          p {
            font-size: rem-calc(15px);
            font-family: $base-font-family;
          }

          .note {
            font-style: italic;
          }
        }
      }
    }

    &.manage-payment-modal {
      .modal-body {
        .account-address-details-container {
          background: #f1f9fd;
        }

        .note {
          font-size: rem-calc(17px);
          margin-bottom: 0;
        }
      }

      .modal-hint {
        font-size: rem-calc(16px);
        line-height: 20px;
        margin: 0 auto 15px;
      }

      .account-address-details-container {
        &.highlight-card {
          width: 100%;
          text-align: left;
          display: flex;
          gap: 16px;

          .custom-checkbox {
            display: flex;
            gap: 8px;

            input {
              margin-top: 0px;
            }
            .checkbox-label {
              text-transform: capitalize;
              font-size: rem-calc(15px);
              font-family: $base-font-family;
              margin-top: 12px;
            }
          }

          .details {
            text-align: left;
            padding-top: 0;
            padding-left: 0px;

            p {
              font-size: rem-calc(15px);
              font-family: $base-font-family;
              margin-bottom: 0;
            }
          }
        }
        &.red-border {
          border: 0.5px solid $red;
        }
      }

      .modal-actions {
        flex-direction: column !important;

        .button {
          padding: 8px !important;
          margin-right: 0 !important;
          margin-bottom: 14px !important;
        }
      }
    }

    &.payment-method-modal {
      width: 100%;
      max-width: 960px !important;

      .modal-content {
        >* {
          display: flex;
          justify-content: flex-start;
        }

        .form-section {
          .credit-image {
            display: none;
          }
        }

        fieldset {
          text-align: left;
          margin-bottom: 15px;
        }

        h4 {
          text-align: left;
          margin-bottom: 10px;
        }

        label {
          text-align: left;
        }

        input {
          width: 100%;
          height: 30px;
          margin-bottom: 20px;
        }

        select {
          height: 30px;
          padding: 2px 10px;
        }

        .select-arrow {
          top: 12px;
        }

        i {
          display: none;
        }

        .radio {
          display: inline-flex;
        }

        .modal-actions {
          .button {
            width: 213px !important;
            padding: 10px !important;
          }
        }

        @media (max-width: 1024px) {
          .row {
            margin: 10px 15px;

            .four {
              padding-right: 10px;
            }
          }

          .radio {
            margin-left: 15px;
          }
        }
      }
    }

    &.mdn-list {
      .modal-actions {
        .button {
          width: 133px !important;
          padding: 8px !important;
        }

        .primary {
          margin-right: 0 !important;
        }
      }

      .modal-body {
        .modal-content {
          display: flex;
          flex-direction: column;

          p {
            font-size: rem-calc(20px);
            line-height: 32px;
            margin-bottom: 10px;

            @media (max-width: $phablet-min-width-minus) {
              font-size: rem-calc(16px);
              line-height: 24px;
            }
          }
        }
      }
    }

    &.migration-confirmation {
      .modal-actions {
        .button {
          width: 280px !important;
          padding: 8px !important;
        }

        @media (max-width: $desktop-min-width-minus) {
          flex-direction: column;

          .primary {
            margin-right: 0 !important;
            margin-bottom: 14px !important;
          }
        }
      }

      .modal-body {
        .modal-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 24px;

          .message {
            font-size: rem-calc(20px);
            line-height: 32px;
            font-weight: bold;
          }

          .info {
            font-size: rem-calc(16px);
            line-height: 24px;
            margin-bottom: 0;
            font-weight: bold;

            .light {
              font-weight: 400;
            }
          }
        }

        @media (max-width: $phablet-min-width-minus) {
          margin-top: 0;

          .content {
            .message {
              font-size: rem-calc(18px);
              line-height: 24px;
            }
          }
        }
      }
    }

    &.out-of-stock-modal {
      .modal-body {
        .modal-content {
          .row {
            font-size: 20px;
            line-height: 32px;
            max-width: 100%;

            @media (max-width: $ipad-min-width-minus) {
              font-size: 18px;
              line-height: 28px;
            }
          }

          .modal-actions {
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: $ipad-min-width-minus) {
              flex-direction: column;

              .primary {
                margin-right: 0 !important;
                margin-bottom: 14px !important;
              }
            }

            a {
              font-size: 20px;
              line-height: 28px;

              @media (max-width: $ipad-min-width-minus) {
                font-size: 16px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }

    &.impacted-modal {
      .modal-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        button {
          width: 360px !important;
          padding: 6px !important;
        }

        .primary {
          margin-right: 0 !important;
          margin-bottom: 14px !important;
        }

        .transparent {
          margin-bottom: 14px !important;
        }
      }

      .modal-content {
        @media (max-width: 641px) {
          overflow-y: visible;
          max-height: 300px;
          margin-top: 0;

          &.row {
            margin-bottom: 0;
          }
        }

        margin: 20px;
      }

      .not-working-link {
        color: $secondary-color;
        cursor: pointer;

        @media (max-width: 641px) {
          margin-bottom: 0;
        }
      }
    }

    &.need-phone-popUp {
      .modal-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        button {
          width: 360px !important;
          padding: 6px !important;
        }

        .primary {
          margin-right: 0 !important;
          margin-bottom: 14px !important;
        }

        .transparent {
          margin-bottom: 14px !important;
        }
      }
    }

    &.select-cc-modal {
      .modal-header {
        width: 100%;
        text-align: left;

        .modal-heading {
          text-align: left;

          @media (max-width: 750px) {
            text-align: center;
          }
        }
      }

      .modal-body {
        .modal-content {
          text-align: left;

          .content {
            padding: 13px;
            display: block;

            .mdn {
              font-size: rem-calc(15px);
              line-height: 17px;
              margin-bottom: 0;
            }
          }

          .payments {
            display: inline-block;
            padding: 13px;

            .action {
              display: inline-block;
              margin-top: 10px;

              .button {
                padding: 11px;
                font-size: rem-calc(20px);
                width: 250px;
              }
            }

            .payments-list {
              display: inline-block;
              height: 37px;
              font-size: rem-calc(15px);
            }
          }

          @media (max-width: 750px) {
            text-align: center;

            .content {
              .mdn {
                font-size: rem-calc(13px);
              }
            }

            .payments {
              .payments-list {
                margin: auto;
                margin-bottom: 20px;
                font-size: rem-calc(13px);
                display: block;
              }

              .action {
                display: block;

                .button {
                  width: 243px;
                }
              }
            }
          }
        }
      }
    }

    &.shipping-address-modal {
      .modal-actions {
        display: flex;
        justify-content: flex-start !important;
        align-items: flex-start !important;

        button {
          width: 140px !important;
        }
      }

      .modal-header {
        text-align: left;

        .modal-heading {
          text-align: left;
        }
      }

      .modal-content {
        text-align: left;

        .twelve {
          width: 100%;
          margin: 0;
          text-align: left;

          &.address-lookup {
            .outlined-label-input {
              width: 66.66666666666666%;

              @media (max-width: 640px) {
                width: 94%;
              }
            }
          }
        }

        label {
          text-align: left;
        }

        .outlined-label-input {
          width: 94%;
          margin-bottom: 15px;
        }

        i {
          display: none;
        }

        .radio {
          display: inline-flex;
        }

        .row {
          margin-bottom: 0;
        }
      }

      @media (max-width: $phablet-min-width) {
        .modal-body {
          label {
            font-size: rem-calc(17px);
            line-height: 20px;
            font-family: $base-font-family;
          }
        }
      }
    }

    &.delivery {
      .modal-body {
        .modal-content {
          display: flex;
          flex-direction: column;

          .id-section {
            padding: 0;

            .id {
              padding: 0px;
              margin-bottom: 10px;
            }
          }

          .dates {
            padding: 0;
          }

          .details {
            padding: 0 10px;
          }

          .date-title {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            margin-top: 20px;
            padding: 0;

            img {
              margin-right: 10px;
            }

            .title {
              font-family: $secondary-font-family-bold;
              font-size: rem-calc(18px);
              margin-bottom: 0;
            }
          }

          .step {
            display: flex;
            justify-content: space-between;
            padding: 0;

            .time {
              font-family: $secondary-font-family-bold;
              font-size: rem-calc(16px);
            }

            .time-details {
              display: flex;
              flex-direction: column;
              font-size: rem-calc(16px);
              align-items: flex-start;
              max-width: 92px;

              .status {
                margin-bottom: 5px;
              }

              .location {
                font-size: rem-calc(12px);
              }
            }
          }
        }
      }
    }

    &.wifi-calling-modal {
      max-width: 375px;
      border: 2px solid $primary-color;
      border-radius: 11px;
      width: 100%;

      .modal-actions {
        button {
          width: 140px !important;
        }
      }

      .modal-body {
        max-height: calc(80vh - 65px);
        overflow-x: hidden;

        .modal-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 15px;

          .form-header {
            padding: 0 10px;
            font-size: rem-calc(15px);
            line-height: 10px;
            margin-bottom: 10px;
            font-weight: bold;
            font-family: $base-font-family-bold;
          }

          .form-description {
            padding: 0 10px;
            font-size: rem-calc(14px);
            line-height: 22px;
            font-family: $base-font-family;
            text-align: left;
          }

          .form-section {
            padding: 10px;

            &:first-child {
              padding: 0px;
            }

            fieldset {
              text-align: left;
              width: 100%;
              margin-bottom: 15px;

              input {
                width: 100%;
                height: 45px;
              }

              label {
                margin-bottom: 9px;
              }

              .icon-search {
                display: none;
              }
            }

            .address-lookup {
              padding: 0;
            }

            .four {
              padding: 0;
            }
          }

          .checks {
            padding: 0 20px 0 10px;
            text-align: left;

            .checkbox {
              display: flex;
              align-items: baseline;
              position: relative;

              .checkbox-label {
                padding-left: 30px;
                font-size: rem-calc(14px);
                cursor: pointer;

                &:before {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 0;
                  margin: 4px;
                  width: 20px;
                  height: 20px;
                  transition: transform 0.28s ease;
                  border-radius: 5px;
                  border: 1px solid $input-border;
                }

                &:after {
                  content: "";
                  display: block;
                  width: 10px;
                  height: 5px;
                  border-bottom: 2px solid $primary-color;
                  border-left: 2px solid $primary-color;
                  transform: rotate(-45deg) scale(0);
                  transition: transform ease 0.25s;
                  position: absolute;
                  top: 11px;
                  left: 10px;
                }
              }

              input[type="checkbox"] {
                width: auto;
                opacity: 0.00000001;
                position: absolute;
                left: 0;
                margin-left: -20px;

                &:checked~label {
                  &:before {
                    border: 1px solid $input-border;
                  }

                  &:after {
                    transform: rotate(-45deg) scale(1);
                  }
                }

                &:focus+label::before {
                  outline: 0;
                }
              }
            }

            .warning {
              padding-left: 30px;

              .note {
                color: $secondary-color;
                font-style: italic;
                font-size: rem-calc(14px);
                line-height: 22px;
              }
            }
          }
        }
      }
    }

    &.edit-wifi-modal {
      .modal-body {
        .modal-content {
          .twelve {
            display: none;
          }
        }
      }
    }

    &.skip-modal {
      .modal-body {
        .modal-content {
          .row {
            width: 100%;

            .msg {
              font-size: 18px;
              line-height: 28px;

              @media (max-width: $phablet-min-width-minus) {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }

          .modal-actions {
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: $phablet-min-width-minus) {
              flex-direction: column;

              .primary {
                margin-right: 0 !important;
                margin-bottom: 14px !important;
              }

              .transparent {
                margin-bottom: 14px !important;
              }
            }

            button {
              width: 160px !important;
              padding: 6px !important;
            }
          }
        }
      }
    }

    &.clear-phone-modal {
      .modal-actions {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $phablet-min-width-minus) {
          flex-direction: column;

          .primary {
            margin-right: 0 !important;
            margin-bottom: 14px !important;
          }

          .transparent {
            margin-bottom: 14px !important;
          }
        }

        button {
          width: 260px !important;
          padding: 6px !important;
        }
      }

      .modal-body {
        .modal-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .row {
            width: 100%;
          }
        }
      }
    }

    &.disable-wifi-modal {
      .modal-actions {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $phablet-min-width-minus) {
          flex-direction: column;

          .primary {
            margin-right: 0 !important;
            margin-bottom: 14px !important;
          }

          .transparent {
            margin-bottom: 14px !important;
          }
        }

        button {
          width: 120px !important;
          padding: 6px !important;
        }
      }
    }

    &.confirm-modal {
      &.delete-duplicate {
        width: 100%;

        .modal-header {
          .modal-close {
            display: none;
          }
        }

        .modal-body {
          .modal-content {
            display: flex;
            flex-direction: column;
            padding-top: 10px;
          }
        }
      }
    }

    &.confirm-change-checkout-modal {
      .modal-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .row {
          width: 100%;
        }
      }

      .msg {
        font-size: rem-calc(17px);
        line-height: 20px;
        padding: 10px 30px 0;
        width: 100%;
      }

      @media (max-width: $tablet-min-width) {
        .row {
          .msg {
            padding: 0;
          }
        }
      }
    }

    &.clean-cart-modal {
      .modal-body {
        text-align: center;

        .msg {
          font-size: rem-calc(17px);
          line-height: 20px;
          max-width: 520px;
          width: 100%;
          text-align: center;
          margin: 0 auto;
        }
      }

      @media (max-width: 641px) {
        .modal-body {
          .msg {
            font-size: rem-calc(17px);
            line-height: 20px;
          }
        }
      }
    }

    &.referral-promo-modal {
      max-width: 740px;
      width: 100%;
      border-radius: 0;
      max-height: 562px;
      height: 100%;

      @media (max-width: 739.9px) {
        background: none;
      }

      .modal-header {
        padding: 0;
        z-index: 999;

        .modal-close {
          background-color: $primary-color;
          width: 44px;
          height: 44px;
          background-image: url(/assets/icon/modal-close.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 15.78px;

          .icon-close {
            z-index: 999;
          }

          .icon-close:before {
            content: "";
          }
        }
      }

      .modal-body {
        overflow: hidden;
        height: 100%;
        max-height: 531px;

        .modal-content {
          padding: 0;

          .content {
            padding: 0;
            display: flex;

            .image-section {
              display: flex;
              flex-basis: 50%;
              overflow: hidden;

              img {
                max-width: 370px;
                height: 531px;
                z-index: 0;
                object-fit: cover;

                @media (max-width: $phablet-min-width-minus) {
                  height: 300px;
                }
              }
            }

            .info-section {
              padding: 0px 40px 0px;
              background-color: #f4f4f4;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 531px;

              @media (max-width: 739.9px) {
                height: 100%;
              }

              .title {
                font-size: rem-calc(30px);
                line-height: 40px;
                margin-bottom: 30px;
                margin-top: 30px;
                max-width: 250px;
              }

              .description {
                font-size: rem-calc(18px);
                line-height: 24px;
                margin-bottom: 33px;
                max-width: 272px;
              }

              .button {
                width: 225px;
                font-size: rem-calc(18px);
                height: 45px;
                margin-bottom: 25px;
              }

              .skip {
                color: $primary-color;
                cursor: pointer;
                font-family: $secondary-font-family;
                font-weight: 500;
                font-size: rem-calc(18px);
                line-height: 24px;
                margin-bottom: 60px;
              }

              .terms {
                color: $primary-color;
                cursor: pointer;
                font-weight: bold;
                font-size: rem-calc(13px);
                line-height: 24px;
                text-decoration: underline;
              }
            }
          }
        }

        @media (max-width: 739.9px) {
          max-height: 562px;
          height: 562px;
          overflow-y: scroll;
          overflow-x: hidden;
          position: relative;
          top: 0px;
          right: 0;
          left: 0;
          max-width: 300px;
          margin: 0 auto;

          .modal-content {
            .content {
              flex-direction: column;

              .image-section {
                img {
                  max-width: 300px;
                  z-index: 0;
                  height: 300px;
                  width: 300px;
                  object-position: top;
                }
              }

              .info-section {
                padding: 30px 12px;

                .title {
                  font-size: rem-calc(25px);
                  margin-bottom: 21px;
                  margin-top: 5px;
                  line-height: 33px;
                }

                .description {
                  margin-bottom: 18px;
                }

                .button {
                  margin-bottom: 25px;
                }

                .skip {
                  margin-bottom: 19px;
                }
              }
            }
          }
        }
      }
    }

    &.auto-renew-modal {
      .msg {
        font-size: 18px;
        line-height: 28px;

        @media (max-width: $phablet-min-width-minus) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      &.large {
        .modal-actions {
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: $phablet-min-width-minus) {
            flex-direction: column;

            .primary {
              margin-right: 0 !important;
              margin-bottom: 14px !important;
            }

            .transparent {
              margin-bottom: 14px !important;
            }
          }

          button {
            width: 240px !important;
            padding: 6px !important;
          }
        }
      }

      .modal-close {
        display: none;
      }
    }
    &.acp-device-modal{
      .acp-desc{
        text-align: center;
        margin: 0 auto 8px;
        &.max {
          max-width: 392px;
        }
      }
      .primary {
         min-width: 166px;
      }
    }
    &.manage-enrollment-modal {
      .primary {
        min-width: 200px;
     }
    }
    
    &.verify-issue-modal {
      @include modal-layout;

      .modal-body {
        overflow: visible;
      }

      .desc {
        margin: 4px 0 8px;
      }

      .modal-actions {
        .button { 
          width: 180px;
        }
      }

      @media (max-width: $phablet-min-width-minus) {
        .modal-actions {
          .button { 
            width: 157px;
          }
        }
      }
    }

    &.verify-success {
      @include modal-layout;

      .modal-actions {
        .button { 
          width: 200px;

          &.transparent {
            display: none;
          }

          @media (max-width: $phablet-min-width-minus) {
            width: 170px;
          }
        }
      }
    }
  }

  .modal-body {
    max-height: calc(90vh - 65px);
    overflow-y: auto;
  }
}

.modal.is-visible,
.modal.fade.in {
  visibility: visible;
  transition: all 0.3s 0.12s;
  opacity: 1;
}

.modal.fade.show {
  visibility: visible;
  transition: all 0.3s 0.12s;
  opacity: 1;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.8);
  visibility: hidden;
  opacity: 0;
}

.modal-backdrop {
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay,
.modal-backdrop.fade.in {
  opacity: 1;
  visibility: visible;
}

.modal-transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0;
}

.modal.is-visible .modal-transition {
  transform: translateY(0);
  opacity: 1;
}